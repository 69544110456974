enum DesignStatus {
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export function numberWithCommas(x: number | string | any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (date: any) => {
  const dateFormat = new Date(date);
  const formattedDate = dateFormat.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

export const designStatusBadge = (status: DesignStatus | string) => {
  switch (status) {
    case DesignStatus.Approved:
      return "bg-[#DCF6DF]";
    case DesignStatus.Pending:
      return "bg-[#FEF3DD]";
    case DesignStatus.Rejected:
      return "bg-[#F8E0E0B8]";
    default:
      return "bg-[#F8E0E0B8]";
  }
};

export const designStatusText = (status: DesignStatus | string) => {
  switch (status) {
    case DesignStatus.Approved:
      return "text-[#39B54A]";
    case DesignStatus.Pending:
      return "text-[#DD9200]";
    case DesignStatus.Rejected:
      return "text-[#F37373]";
    default:
      break;
  }
};
