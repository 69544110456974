import React from "react";
import SingleNotification from "../../component/SingleNotification";

const Notifications = () => {
  return (
    <div className="h-screen bg-white p-6">
      <div className="w-full border-b h-8 flex gap-x-4 text-sm text-brandBlack-400">
        <div className=" relative px-2">
          All
          <span className="w-full absolute left-0 h-1 bg-brandOrange-100 bottom-0 rounded"></span>
        </div>
        <div>Order & Updates</div>
        <div>Promotions</div>
      </div>
      <div>
        <SingleNotification />
      </div>
    </div>
  );
};

export default Notifications;
