import React from "react";
import { FiDownload } from "react-icons/fi";
import File from "../assets/flat-color-icons_document.svg";

const Resource = ({data}: any) => {
  return (
    <div className="flex flex-col gap-y-1 h-72 w-48">
      <div className="h-44 w-full bg-[#EBF6FF] rounded-lg flex justify-center items-center">
        <img src={File} alt="" />
      </div>
      <div>
        <p className="text-[#303642] truncate w-full">
          {data?.name}
        </p>
        <p className="text-[#464646] text-sm">20 Feb, 2024</p>
      </div>
      <span className="h-6 w-6 rounded-full bg-[#FFF6EB] flex justify-center items-center">
        <FiDownload className="text-brandOrange-100" size={16} />
      </span>
    </div>
  );
};

export default Resource;
