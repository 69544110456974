import React from "react";
import { IoStarSharp } from "react-icons/io5";
import { numberWithCommas } from "../utils";

const RatedDesigns = ({ design, title }: any) => {
  console.log({ design });
  return (
    <div className="my-4">
      <p className="mb-4">{title}</p>
      <div className="flex gap-x-4 items-center">
        <img src="https://picsum.photos/95/130" alt="" />
        <div>
          <p className="text-brandOrange-100 underline underline-offset-4">
            {design?.name}
          </p>
          <p>₦{numberWithCommas(`${design?.totalAmount}`)} </p>
          <p className="font-bold">{design?.usage} Uses</p>
          <div className="flex items-center">
            <div className="flex">
              <IoStarSharp color={"#F15B2B"} />
              <IoStarSharp color={"#F15B2B"} />
              <IoStarSharp color={"#DEDEDE"} />
              <IoStarSharp color={"#DEDEDE"} />
              <IoStarSharp color={"#DEDEDE"} />
            </div>
            <p>(4/5)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatedDesigns;
