import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useState } from "react";

export interface IValue {
  id: number;
  name: string;
}

function Dropdown({
  selected,
  setSelected,
  values,
}: {
  values: IValue[];
  selected: IValue;
  setSelected: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    }>
  >;
}) {
  //   const [selectedPerson, setSelectedPerson] = useState(selected[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <ListboxButton
        className={
          "w-full bg-transparent rounded text-brandOrange-100 relative h-full"
        }
      >
        {selected.name}
      </ListboxButton>
      <ListboxOptions className={"absolute w-full border z-[10000] bg-white"}>
        {values.map((value: IValue) => (
          <ListboxOption
            key={value.id}
            value={value}
            className="data-[focus]:bg-brandOrange-600 data-[focus]:text-brandOrange-100 w-full px-2 cursor-pointer data-[focus]:font-medium"
          >
            {value.name}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}

export default Dropdown;
