import React, { useEffect, useState } from "react";
import Resource from "../../component/Resource";
import { apiFetch } from "../../services";

const Resources = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>();
  const [pageSize, setPageSize] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resources, setResources] = useState<[]>();
  useEffect(() => {
    const getResources = async () => {
      setLoading(true);
      const { data, isLoaded } = await apiFetch(
        `/api/Resources/Search?Page=${currentPage}&PageSize=10`,
        "GET"
      );
      if (isLoaded && data?.statusCode == 200) {
        console.log(data);
        setResources(data.data.data);
        setPage(data.data.page);
        setPageSize(data.data.pageSize);
        setTotalCount(data.data.totalCount);
        setTotalPages(data.data.totalPages);
        setLoading(false);
      }
    };

    getResources();
  }, [currentPage]);

  return (
    <div className="h-screen bg-white p-6">
      <p>Resources</p>
      <div className="flex gap-x-4 flex-wrap">
        {resources &&
          resources.map((resource: any) => <Resource data={resource} />)}
      </div>
    </div>
  );
};

export default Resources;
