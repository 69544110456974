import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import * as yup from "yup";
import { FaCheck } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryInput } from "../component/PrimaryInput";
// import Template from "../components/Template";
import { Toaster, toast } from "sonner";
// import { jwtDecode } from "jwt-decode";
// import { IJwtToken } from "../interfaces";
import ResetPasswordModal from "../component/ResetPasswordModal";
import { apiPost } from "../services";
import Cookies from "js-cookie";

const Login = () => {
  const benefitList = [
    "Fast checkout with your details already saved",
    "Track your orders and view order history",
    "Receive our latest news and offers.",
    "Remember all the special occasions with our event reminder",
  ];

  type Inputs = {
    email: string;
    password: string | any;
  };

  const schema = yup
    .object()
    .shape({
      email: yup.string().email().required("Please input Email"),
      password: yup
        .string()
        .required("Password is required")
        .min(4, "Password length should be at least 4 characters"),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    resolver: yupResolver(schema), // yup, joi and even your own.
  });

  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    setLoading(true);
    const { data: info, isLoaded } = await apiPost(
      "/api/Account/DesignerLogin",
      "POST",
      data
    );


    if (isLoaded && info.username) {
      setLoading(false);
      Cookies.set("accessToken", info.accessToken);
      Cookies.set("refreshToken", info.refreshToken);
      toast.success(`${info.username} is logged in successfully!`);
      navigate("/dashboard");
    } else {
      setLoading(false);
      toast.error(`Sorry, ${info.message}`);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  return (
    <>
      {openPasswordModal ? (
        <ResetPasswordModal
          isOpen={openPasswordModal}
          setIsOpen={setOpenPasswordModal}
          title={"Reset Password"}
        />
      ) : (
        ""
      )}
      <Toaster richColors position="top-right" />
      <div>
        <div className="mt-8">
          <div className="w-full h-[48px] bg-transparent grid grid-cols-2 lg:invisible">
            <Link to={"/login"}>
              <div className="col-span-1 h-full border-b-2 text-brandOrange-200 bg-[#FFF6EB] border-brandOrange-200 flex justify-center items-center cursor-pointer">
                <p className="font-medium">Existing User</p>
              </div>
            </Link>
            <Link to={"/register"}>
              <div className="col-span-1 h-full border-b-2 flex justify-center items-center cursor-pointer">
                <p className="text-sm  font-medium">New User</p>
              </div>
            </Link>
          </div>
          <div className="flex sm:flex-col lg:flex-row w-full sm:items-center lg:items-start lg:justify-center lg:px-5 lg:gap-x-10">
            <div className="drop-shadow-2xl bg-white mt-2 rounded-xl px-4 py-8 w-[568px]">
              <h4 className="font-medium text-2xl mt-10">Sign in</h4>
              <form
                onSubmit={handleSubmit(submitForm)}
                className="mt-5 w-full"
                autoComplete="off"
              >
                <div className="my-4">
                  <PrimaryInput
                    defaultValue={""}
                    name="email"
                    register={register}
                    error={errors.email}
                    placeholder="Email Address"
                    className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1"
                    label="Email Address"
                    type="email"
                  />
                </div>
                <div className="my-4">
                  <>
                    <label
                      className="text-brandBlack-100 text-sm"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        autoComplete="off"
                        id={"password"}
                        placeholder="Password"
                        {...register("password")}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        disabled={false}
                        className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1 relative"
                        style={{
                          borderColor: `${
                            errors.password?.message ? "red" : ""
                          }`,
                        }}
                      />
                      <span
                        className="absolute right-5 -translate-y-1/2 top-1/2"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <FiEyeOff size={24} color="#727272" />
                        ) : (
                          <FiEye size={24} color="#727272" />
                        )}
                      </span>
                    </div>
                    <p className="text-brandRed-100 text-sm mt-1">
                      {/* {errors.password?.type} */}
                      {errors?.password?.message as unknown as string}
                    </p>
                  </>
                </div>
                <div className="mt-2 flex justify-between w-full items-start">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="h-4 w-4 mr-2"
                    />
                    <p className="text-sm">Remember me</p>
                  </div>
                  <p
                    className="text-sm text-brandBlue-300 cursor-pointer"
                    onClick={() => setOpenPasswordModal(true)}
                  >
                    Forgot password?
                  </p>
                </div>
                <div>
                  <button
                    type="submit"
                    className="my-6 rounded-lg border bg-brandOrange-100 text-white w-full h-11"
                  >
                    {loading ? (
                      <ClipLoader
                        color={"white"}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Sign in"
                    )}
                  </button>
                  <div className="or text-brandBlack-200">OR</div>
                  <button className="mt-6 rounded-lg border bg-transparent border-brandBlack-200 text-brandBlack-200 w-full h-11">
                    Continue with google
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-10 p-4 max-w-[511px]">
              <div className="w-full">
                <div className="mt-10">
                  <h4 className="text-2xl font-medium">New to Fotologue?</h4>
                  <p className="mt-2">
                    Explore a world of creativity. Setting up an account with us
                    is quick and easy.
                  </p>
                </div>
                <div className="mt-5">
                  {benefitList.map((benefit) => (
                    <div key={benefit} className="flex items-center my-4">
                      <FaCheck color="#39B54A" size={24} />
                      <p className="ml-4">{benefit}</p>
                    </div>
                  ))}
                </div>
                <Link to={"/register"}>
                  <button className="mt-5 rounded-lg border border-brandOrange-100 text-brandOrange-100 w-full h-11">
                    Create a free account
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
