import React from "react";
import { HiOutlineMegaphone } from "react-icons/hi2";

const SingleNotification = () => {
  return (
    <div className="w-full flex gap-x-4 justify-between py-4 items-center h-36 border-b">
      <div className="h-12 w-12 rounded-full bg-[#FFF6EB] flex justify-center items-center self-start">
        <HiOutlineMegaphone fontSize={22} color="#F89521" />
      </div>
      <div className="flex-1 flex flex-col gap-y-2 text-sm mr-8">
        <h2 className="font-medium text-base">Order delivered</h2>
        <p className="">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con
        </p>
        <h5 className="text-brandBlack-400">Feb 14. 2024</h5>
      </div>
      <div className="text-xl font-medium cursor-pointer">...</div>
    </div>
  );
};

export default SingleNotification;
