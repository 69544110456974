import React, { useEffect, useState } from "react";
import Dropdown, { IValue } from "../component/DropdownComponent";
import { FiBell, FiTrendingUp } from "react-icons/fi";
import TotalRevenueBarChart from "../component/charts/TotalRevenueBarChart";
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineColorLens } from "react-icons/md";
import { FiEye, FiEdit2 } from "react-icons/fi";
import {
  designStatusBadge,
  designStatusText,
  numberWithCommas,
} from "../utils";
import { apiFetch } from "../services";
import Cookies from "js-cookie";

const Dashboard = () => {
  const [recentDesigns, setRecentDesigns] = useState<[]>();
  const [feedbacks, setFeedbacks] = useState([]);

  const getTheme = async () => {
    const { data, isLoaded } = await apiFetch("/api/Storefront/Get", "GET");
    console.log({ data });
    if (isLoaded && data.statusCode == 200) {
      Cookies.set("tenantId", data.data.tenantId);
      Cookies.set("storeFrontId", data.data.storeFrontId);
      Cookies.set("logo", data.data.logo);
      Cookies.set("storeTitle", data.data.storeFrontName);
    }
  };

  const getRecentDesigns = async () => {
    const { data, isLoaded } = await apiFetch(
      "/api/CreatorDesignTemplate/GetRecentDesigns",
      "GET"
    );
    console.log({ data });
    if (isLoaded && data.statusCode == 200) {
      console.log(data);
      setRecentDesigns(data.data);
    }
  };

  const getFeedbacks = async () => {
    const { data, isLoaded } = await apiFetch(
      "/api/CreatorDesignTemplate/GetDesignerFeedbacks",
      "GET"
    );
    console.log({ data });
    if (isLoaded && data.statusCode == 200) {
      console.log(data);
      setFeedbacks(data.data);
    }
  };

  useEffect(() => {
    getFeedbacks();
    getRecentDesigns();
    getTheme();
  }, []);

  const intervals = [
    { id: 1, name: "Week" },
    { id: 2, name: "Month" },
    { id: 3, name: "Year" },
  ];
  const [revenueInterval, setRevenueInterval] = useState<IValue>(intervals[0]);

  return (
    <div className=" bg-green-300h-screen flex flex-col gap-y-8">
      <div className="h-[357px] grid grid-cols-3 gap-x-6">
        <div className="col-span-1 bg-white rounded-md">
          <div className="grid-col-1 bg-white shadow-md shadow-gray-200 rounded-lg p-4">
            <p>Total Revenue</p>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="font-black text-2xl">N100,000</p>
              <div className="w-[68px] h-8 rounded-lg relative bg-[#FDEFEB]">
                <Dropdown
                  selected={revenueInterval}
                  setSelected={setRevenueInterval}
                  values={intervals}
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 my-2">
              <span>
                <FiTrendingUp color="#39B54A" />
              </span>
              <p className="text-[#39B54A] text-sm">2.4</p>
            </div>
            <TotalRevenueBarChart />
            <div className="flex justify-end text-brandOrange-100 text-sm items-center gap-x-1">
              View graph <FaChevronRight size={10} />
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md">
          <div className="grid-col-1 bg-white shadow-md shadow-gray-200 rounded-lg p-4">
            <div className="flex gap-x-2 items-center">
              <MdOutlineColorLens size={25} />
              <p>Total Designs</p>
            </div>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="font-black text-2xl">24</p>
              <div className="w-[68px] h-8 rounded-lg relative bg-[#FDEFEB]">
                <Dropdown
                  selected={revenueInterval}
                  setSelected={setRevenueInterval}
                  values={intervals}
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 my-2">
              <span>
                <FiTrendingUp color="#39B54A" />
              </span>
              <p className="text-[#39B54A] text-sm">2.4</p>
            </div>
            <TotalRevenueBarChart />
            <div className="flex justify-end text-brandOrange-100 text-sm items-center gap-x-1">
              View graph <FaChevronRight size={10} />
            </div>
          </div>
        </div>

        <div className="grid-col-1 bg-white shadow-md shadow-gray-200 rounded-lg pt-4 px-8">
          <div className="w-full border-b h-12 flex gap-x-2 items-center">
            <FiBell size={25} />
            <p>Notifications</p>
          </div>
          <div className="w-full flex justify-between h-12 mt-4">
            <div>
              <p className="font-medium text-sm">Approval Update</p>
              <p className="text-sm text-brandBlack-200">
                Design 'Your love' has been approved
              </p>
            </div>
            <p className="text-brandBlack-200 text-sm">2m</p>
          </div>
          <div className="w-full flex justify-between h-12 mt-4">
            <div>
              <p className="font-medium text-sm">Approval Update</p>
              <p className="text-sm text-brandBlack-200">
                Design 'Your love' has been approved
              </p>
            </div>
            <p className="text-brandBlack-200 text-sm">2m</p>
          </div>
          <div className="w-full flex justify-between h-12 mt-4">
            <div>
              <p className="font-medium text-sm">Approval Update</p>
              <p className="text-sm text-brandBlack-200">
                Design 'Your love' has been approved
              </p>
            </div>
            <p className="text-brandBlack-200 text-sm">2m</p>
          </div>
          <div className="w-full flex justify-between h-12 mt-4">
            <div>
              <p className="font-medium text-sm">Approval Update</p>
              <p className="text-sm text-brandBlack-200">
                Design 'Your love' has been approved
              </p>
            </div>
            <p className="text-brandBlack-200 text-sm">2m</p>
          </div>
          <div className="flex justify-end text-brandOrange-100 text-sm items-center gap-x-1">
            View notification <FaChevronRight size={10} />
          </div>
        </div>
      </div>
      <div className="h-[504px] grid grid-cols-3 gap-x-6">
        <div className="col-span-2 bg-white rounded-md">
          <div className="mt-8 flow-root px-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden  ring-opacity-5 sm:rounded-lg">
                  <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Design Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {recentDesigns && recentDesigns.length > 0
                        ? recentDesigns.map((design: any) => (
                            <tr key={design.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-brandBlack-300">
                                {design.designName}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-brandBlack-300">
                                {design.category}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300">
                                {numberWithCommas(design.amount)}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                <div
                                  className={` flex items-center justify-center h-8 w-[70px] rounded-md text-xs ${designStatusText(
                                    design.status
                                  )} ${designStatusBadge(design.status)}`}
                                >
                                  {design.status}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                <div className="flex gap-x-4">
                                  <div className="bg-[#FDEFEB] p-1 rounded-full">
                                    <FiEye size={18} color="#F89521" />
                                  </div>
                                  <div className="bg-[#FDEFEB] p-1 rounded-full">
                                    <FiEdit2 size={18} color="#F89521" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        : "No Orders"}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md py-6 px-4">
          Ratings & Feedback
          {
            feedbacks.length > 0 ?
            <div className="mt-4">{feedbacks}</div>
            : <p className="mt-8 ">No Feedbacks...</p>
          }
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
