import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { toast } from "sonner";
// import { apiPost } from "../service/useApi";

export default function ResetPasswordModal({
  isOpen,
  setIsOpen,
  title,
}: {
  isOpen: boolean;
  setIsOpen: any;
  title: string;
}) {
  let [email, setEmail] = useState("");
  let [loading, setLoading] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const sendMail = async () => {
    // console.log(email);
    // //
    // const storeFrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    // const data = {
    //   email,
    //   tenantId,
    //   storeFrontId,
    // };
    // console.log(JSON.stringify(data));

    // const { data: info, isLoaded } = await apiPost(
    //   "/api/Customer/ResetPassword",
    //   "POST",
    //   data
    // );
    // if (isLoaded && info.statusCode == 200) {
    //   setLoading(false);
    //   toast.success(info.message);
    //   closeModal();
    // }
  };

  return (
    <>
     
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className=" w-[407px] h-[276px] transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form autoComplete="off">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className=" text-gray-500 text-center">
                        Simply enter your email address below and we will email
                        you a link to reset your password.
                      </p>
                    </div>

                    <div className="mt-2">
                      <label htmlFor="email">Email Address:</label>
                      <input
                        type="email"
                        className="border block w-full rounded h-10 mt-1 px-2 outline-brandOrange-200"
                        placeholder="eg. johndoe@fotologue.com"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mt-4 flex justify-between gap-x-4">
                      <button
                        type="button"
                        className="inline-flex justify-center items-center rounded border h-12 border-brandOrange-200 text-brandOrange-200 bg-white w-full px-4 py-2 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center items-center rounded h-12 border border-transparent bg-brandOrange-200 w-full text-white px-4 py-2 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={sendMail}
                      >
                        Send email
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
