import Cookies from "js-cookie";
import { API_BASE_URL } from "../App";
import { useNavigate } from "react-router-dom";

export const apiFetch = async (url: string, method: string, body?: any) => {
  const storeId = Cookies.get("storeFrontId");

  const tenantId = localStorage.getItem("tenantId");
  const accessToken = Cookies.get("accessToken");

  const uri = API_BASE_URL + url;

  let res;

  try {
    body
      ? (res = await fetch(uri, {
          method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "Access-Control-Allow-Headers": "Content-Type",
            storeFrontId: storeId || "",
            tenantId: tenantId || "",
          },
          body,
        }))
      : (res = await fetch(uri, {
          method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "Access-Control-Allow-Headers": "Content-Type",
            storeFrontId: storeId || "",
            tenantId: tenantId || "",
          },
        }));
    console.log({ res });
    console.log(res.status);
    if (res.status === 401 || res.status === 403) {
      window.location.href = "/login";
    }
    const data = await res.json();
    if (data) {
      return {
        data,
        isLoaded: true,
      };
    } else {
      return {
        data: null,
        isLoaded: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      data: null,
      isLoaded: true,
    };
  }
};

export const apiPost = async (url: string, method: string, body: any) => {
  const storeId = Cookies.get("storeFrontId");
  const tenantId = Cookies.get("tenantId");
  const accessToken = Cookies.get("accessToken")
    ? Cookies.get("accessToken")
    : "";
  const uri = API_BASE_URL + url;
  console.log({ body });
  try {
    const res = await fetch(uri, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        storeFrontId: storeId || "",
        tenantId: tenantId || "",
      },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    console.log(data);
    if (data) {
      return {
        data,
        isLoaded: true,
        error: false,
      };
    } else {
      return {
        data,
        isLoaded: true,
        error: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      data: null,
      isLoaded: true,
      error,
    };
  }
};
