import React from "react";
import { FiSearch, FiBell } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "./SideNav";

const TopNav = () => {
  const route = useLocation();
  const currentPage = navItems.filter((item) =>
    route.pathname.includes(item.route.split("/")[1])
  );
  return (
    <div
      className={`h-16 border flex items-center pl-[288px] justify-between px-4 fixed top-0 right-0 w-full bg-white ${
        route.pathname === "/login" ? "hidden" : "flex"
      }`}
    >
      <p className="font-medium text-xl">Hi Tobi</p>
      <div className="flex gap-x-6">
       <button className="w-36 rounded-lg bg-brandOrange-100 text-white h-10 font-medium">Create Design</button>

        <div className="h-9 w-9 bg-brandOrange-300 rounded-full flex justify-center items-center">
          <Link to={"/notifications"}>
            <FiBell color="#F89521" size={"20"} />
          </Link>
        </div>
        <div className="flex gap-x-4">
          <div className="h-9 w-9 bg-slate-400 rounded-full"></div>
         
        </div>
      </div>
    </div>
  );
};

export default TopNav;
