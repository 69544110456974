import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const LandingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);

  useEffect(() => {
    navigate("/dashboard");
    window.location.href = "/dashboard";
  }, []);
  return <div>LandingPage</div>;
};

export default LandingPage;
